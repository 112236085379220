import React from 'react';
import Markdown from 'react-markdown';
import PropTypes from 'prop-types';

import Graphic from 'components/Graphic.js';
import Question from 'components/Question.js';
import {setStorageProperty} from 'src/utils.js';
import ContentsTable from './ContentsTable';
import {slugify} from '../utils.js';

export default class QuestionGroup extends React.Component {
    static propTypes = {
        description: PropTypes.string,
        id: PropTypes.string,
        integration: PropTypes.string,
        name: PropTypes.string,
        number: PropTypes.number.isRequired,
        questions: PropTypes.array,
        questionsState: PropTypes.array.isRequired,
        readings: PropTypes.array,
        repeat: PropTypes.number,
        validate: PropTypes.func.isRequired,
    };

    constructor() {
        super();
        this.state = {
            selectedReadings: [],
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        const {readings} = this.props;
        if (readings?.length) {
            // setTimeout is used to make sure this DOM action is triggered after react is mounted
            setTimeout(() => {
                const selectedReadings = [];
                readings.forEach((reading) => {
                    const input = document.getElementById(reading.label);
                    if (input?.checked) {
                        selectedReadings.push(reading.label);
                    }
                });
                this.setState({
                    selectedReadings,
                });
            }, 0);
        }
    }

    handleChange(event) {
        const {validate} = this.props;
        const {id, checked} = event.target;
        const selectedReadings = [...this.state.selectedReadings];

        if (checked) {
            selectedReadings.push(id);
        } else {
            selectedReadings.splice(selectedReadings.indexOf(id), 1);
        }

        this.setState({selectedReadings});

        validate(id, checked);

        setStorageProperty('racgp-course', id, {
            value: checked,
            type: 'readings',
        });
    }

    render() {
        const {
            description,
            id,
            name,
            number,
            questions,
            questionsState,
            readings,
            repeat,
            validate,
        } = this.props;

        const loop = repeat ? repeat : 1;
        return (
            <fieldset className={repeat ? 'repeater' : ''} id={id}>
                {questions &&
                    [...Array(loop)].map((group, tab) => (
                        <section className="group" key={tab}>
                            <ContentsTable
                                index={number}
                                integration={this.props.integration}
                                loop={loop}
                                name={name}
                                questions={questions}
                                questionsState={questionsState}
                                tab={tab}
                            />
                            <section className="questions">
                                {repeat && name ? (
                                    <h2>{`${number + 1}. ${name} — Patient ${
                                        tab + 1
                                    }`}</h2>
                                ) : (
                                    <>
                                        {name && (
                                            <h2>{`${number + 1}. ${name}`}</h2>
                                        )}
                                        <Markdown>{description}</Markdown>
                                    </>
                                )}
                                {repeat && tab === 0 && (
                                    <Markdown>{description}</Markdown>
                                )}
                                {questions.map((question, i) => (
                                    <Question
                                        answers={question.answers}
                                        correctAnswer={question.correctAnswer}
                                        id={question.id}
                                        integration={this.props.integration}
                                        key={i}
                                        label={question.label}
                                        multiple={question.multiple}
                                        requiresIntegration={question.requiresIntegration}
                                        scale={question.scale || []}
                                        subQuestions={question.subQuestions}
                                        tab={repeat && tab + 1}
                                        type={question.type}
                                        validate={validate}
                                    />
                                ))}
                            </section>
                        </section>
                    ))}

                {readings && (
                    <section className="group">
                        <ContentsTable
                            index={number}
                            integration={this.props.integration}
                            name={name}
                            questions={readings}
                            questionsState={questionsState}
                        />
                        <section className="readings">
                            {!repeat && (
                                <>
                                    {name && (
                                        <h2>{`${number + 1}. ${name}`}</h2>
                                    )}
                                    <Markdown>{description}</Markdown>
                                </>
                            )}
                            {readings.map((reading, i) => (
                                <p className="reading" key={i}>
                                    <label id={slugify(reading.label)}>
                                        <input
                                            id={reading.label}
                                            onChange={this.handleChange}
                                            type="checkbox"
                                        />
                                        <Graphic name="checkbox" />
                                    </label>
                                    <a
                                        href={reading.url}
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        <Markdown
                                            disallowedElements={['p']}
                                            unwrapDisallowed
                                        >
                                            {reading.label}
                                        </Markdown>
                                    </a>
                                    {reading.caption}
                                </p>
                            ))}
                        </section>
                    </section>
                )}
            </fieldset>
        );
    }
}
