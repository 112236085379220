import React from 'react';
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';
import {slugify} from '../utils.js';

export default class ContentsTable extends React.Component {
    static propTypes = {
        index: PropTypes.number.isRequired,
        integration: PropTypes.string,
        loop: PropTypes.number,
        name: PropTypes.string.isRequired,
        questions: PropTypes.array,
        questionsState: PropTypes.array.isRequired,
        tab: PropTypes.number,
    };

    static defaultProps = {
        questions: [],
        loop: 0,
        tab: 0
    };

    getLabel(question) {
        return this.props.loop > 1
            ? `Patient ${this.props.tab + 1}: ${question.label}`
            : question.id || question.label;
    }

    renderQuestionLabel(question) {
        if (question.type === 'heading') {
            return null;
        }

        if (question.requiresIntegration && question.requiresIntegration !== this.props.integration) {
            return null;
        }
        
        const questionAnswered = this.props.questionsState.find((q) =>
            this.getLabel(question).includes(q.label),
        ).answered;
        return (
            <li className={questionAnswered ? 'complete' : 'incomplete'} key={question.label}>
                <a href={`#${slugify(this.getLabel(question))}`}>
                    <Markdown disallowedElements={['p']} unwrapDisallowed>
                        {question.id ? question.id : question.label}
                    </Markdown>
                </a>
            </li>
        );
    }

    get allRequiredQuestionsAnswered() {
        const {questions, questionsState} = this.props;
        const unAnsweredQuestions = [];
        questions.forEach((question) => {
            const currentQuestion = questionsState.find((q) =>
                this.getLabel(question).includes(q.label),
            );
            if (!currentQuestion?.answered && currentQuestion) {
                unAnsweredQuestions.push(currentQuestion);
            }
        });
        return unAnsweredQuestions.length < 1;
    }

    render() {
        const {index, loop, name, questions, tab} = this.props;

        return (
            <aside>
                <a
                    className={
                        this.allRequiredQuestionsAnswered
                            ? 'complete'
                            : 'incomplete'
                    }
                    href={`#${slugify(name)}`}
                >
                    <span>
                        {this.allRequiredQuestionsAnswered ? '✓' : index + 1}
                    </span>
                    {loop > 1 ? `${name}  — Patient ${tab + 1}` : name}
                </a>
                <ul>
                    <section>
                        {questions.map((question) =>
                            this.renderQuestionLabel(question),
                        )}
                    </section>
                </ul>
            </aside>
        );
    }
}
