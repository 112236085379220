import React from 'react';

import Layout from 'components/Layout.js';
import QuestionGroup from 'components/QuestionGroup.js';
import Graphic from 'components/Graphic.js';
import {setStorageProperty} from 'src/utils.js';

import course from 'data/course.json';
import {populateForm, slugify, storageIsAvailable} from '../utils.js';
import racgpLogo from 'media/racgp_logo.jpg';

export default class View extends React.Component {
    constructor() {
        super();
        // Answers object
        // label: string
        // required: boolean
        // answered: boolean
        const questionsState = [];

        let integration = '';

        course.sections.forEach((section) => {
            if (section.questions) {
                if (section.repeat) {
                    for (let i = 0; i < section.repeat; i++) {
                        section.questions.forEach((question) => {
                            questionsState.push({
                                answered: false,
                                label: question.id
                                    ? `Patient ${i + 1}: ${question.id}`
                                    : `Patient ${i + 1}: ${question.label}`,
                                required: question.required || false,
                            });
                        });
                    }
                } else {
                    section.questions.forEach((question) => {
                        if (question.type !== 'heading') {
                            questionsState.push({
                                answered: false,
                                label: question.id ? question.id : question.label,
                                required: question.required || false,
                            });
                        }
                    });
                }
            }
            if (section.readings) {
                section.readings.forEach((reading) => {
                    questionsState.push({
                        answered: false,
                        label: reading.id ? reading.id : reading.label,
                        required: false,
                    });
                });
            }
        });
        this.state = {questionsState, integration};

        this.handleSubmit = this.handleSubmit.bind(this);
        this.validate = this.validate.bind(this);
    }

    componentDidMount() {
        if (storageIsAvailable()) {
            const data = localStorage.getItem('racgp-course');
            if (data) {
                const formData = populateForm(JSON.parse(data));
                const {questions, integration} = formData;
                const questionsState = [...this.state.questionsState];
                questions.forEach((question) => {
                    const index = questionsState.findIndex((x) =>
                        question.includes(x.label),
                    );
                    if (index !== -1) {
                        questionsState[index].answered = true;
                    }
                    this.setState({questionsState});
                });
                this.setState({integration});
            }
        }

        window.addEventListener('racgp_change', () => {
            const data = JSON.parse(localStorage.getItem('racgp-course'));
            const PMS = data['PMS'];
            if (PMS) {
                this.setState({
                    integration: PMS.value[0] || ''
                });
            }
        });
    }

    handleSubmit(event) {
        const {questionsState} = this.state;

        const invalidAnswers = questionsState.filter(
            (question) => !question.answered && question.required,
        );

        if (invalidAnswers.length > 0) {
            event.preventDefault();
            // eslint-disable-next-line no-alert
            alert('Please ensure you have answered every question.');
        }
    }

    validate(question, valid) {
        const questionsState = [...this.state.questionsState].map((q) => {
            const newQuestion = {...q};
            if (newQuestion.label === question) {
                newQuestion.answered = valid;
            }
            return newQuestion;
        });

        this.setState({questionsState});
    }

    handleChange(event) {
        const {id, checked} = event.target;

        setStorageProperty('racgp-course', id, {
            value: checked,
            type: 'readings',
        });
    }

    render() {
        return (
            <Layout className="racgp">
                <form
                    action="/racgp-evaluation/"
                    data-netlify="true"
                    method="post"
                    name="RACGP course"
                    netlify-honeypot="bot-field"
                    onSubmit={this.handleSubmit}
                >
                    <section className="group contents">
                        <aside>
                            <img alt="RACGP CPD Accredited Activity Logo" src={racgpLogo} />
                        </aside>
                        <article>
                            <h1>
                                {
                                    'Using Digital Health Tools in the Primary Care Setting'
                                }
                            </h1>
                            <p>
                                {
                                    'This course has been assembled by HealthShare to address the continuously evolving primary care landscape. It provides an enhanced understanding of the impacts and benefits of technological changes over the years. There is a particular focus on educating health practitioners on examples of digital health tools which support daily clinical practice such as those offered by HealthShare. Upon completing this accredited course, you will be awarded 40 CPD points.'
                                }
                            </p>
                            <p>
                                {
                                    "Don't forget to bookmark this activity on your browser. If you leave this webpage your answers will be saved and you may return to them at any time simply by revisiting the page on the same device."
                                }
                            </p>
                            <p>
                                {
                                    'Once the course is submitted, an evaluation form will be displayed. Your feedback can assist us with ongoing quality improvement of the activity.'
                                }
                            </p>
                            <p>
                                {
                                    'If you have any concerns regarding any aspects of this activity, you '
                                }
                                <a
                                    href="https://www.racgp.org.au/FSDEDEV/media/documents/Education/Professional%20development/QI-CPD/CPD-GP-Feedback-form.pdf"
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    {
                                        'may use this form to provide confidential feedback to RACGP'
                                    }
                                </a>
                                {' and email your completed form to '}
                                <a href="mailto:cpd.national@racgp.org.au">
                                    {'cpd.national@racgp.org.au'}
                                </a>
                                {'.'}
                            </p>
                            <h2>{'Learning outcomes'}</h2>
                            <p>
                                {
                                    'By the end of this course, you will be able to:'
                                }
                            </p>
                            <ol>
                                <li>
                                    {
                                        'Evaluate the impact of the COVID-19 pandemic on your clinical practice and describe areas of improvement in patient-centred care'
                                    }
                                </li>
                                <li>
                                    {
                                        'Assess current practices in sourcing fact sheets and locating suitable referral  pathways for patients'
                                    }
                                </li>
                                <li>
                                    {
                                        'Analyse whether the implementation of a pre-consultation questionnaire would  be/is beneficial to your practice'
                                    }
                                </li>
                                <li>
                                    {
                                        "Use HealthShare's digital tools in clinical practice - generate referral letters,  access fact sheets and share with patients, and use BetterConsult (Best Practice users only)"
                                    }
                                </li>
                                <li>
                                    {
                                        'Determine whether the use of HealthShare’s digital tools has helped improve workflow efficiency and patient care'
                                    }
                                </li>
                                <li>
                                    {
                                        'Determine ways in which digital health tools can be optimised to improve patient care or your clinical workflow'
                                    }
                                </li>
                            </ol>
                        </article>
                    </section>
                    <input
                        name="form-name"
                        type="hidden"
                        value="RACGP course"
                    />
                    <input name="bot-field" type="hidden" />
                    {course.sections.map((section, i) => (
                        <QuestionGroup
                            description={section.description}
                            id={slugify(section.name)}
                            integration={this.state.integration}
                            questionsState={this.state.questionsState}
                            key={i}
                            name={section.name}
                            number={i}
                            questions={section.questions}
                            readings={section.readings}
                            repeat={section.repeat}
                            validate={this.validate}
                        />
                    ))}
                    <section className="group">
                        <aside />
                        <section className="questions">
                            <>
                                <h2>{'Submission'}</h2>
                                <p>
                                    {
                                        'Thank you for taking the time to extend your knowledge of digital health tools. We hope you have found this course relevant and applicable to your practice.'
                                    }
                                </p>
                                <p>
                                    {
                                        'Please click submit to complete this course. Your answers will then be audited before you are emailed a certificate of completion. The issuing of the certificate and reporting of course completion to RACGP will occur within one month of completing the course. Please keep a copy of this certificate in case discrepancies occur regarding your RACGP credit point statement records.'
                                    }
                                </p>
                            </>
                        </section>
                    </section>
                    <section className="group">
                        <aside />
                        <div className="btn-group">
                            <button type="submit">
                                {'Submit entire course'}
                            </button>
                        </div>
                    </section>
                </form>
            </Layout>
        );
    }
}
